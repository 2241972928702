import React from "react"
import { Link, graphql } from "gatsby"

//import Image from "../components/image"
import SEO from "../components/seo"
import Layout from "../components/layout";



const BlogPost = ({node}) => {
  return (
    <li>
      <Link to={node.slug}>{node.title}</Link>
      <img src={node.image.resize.src} alt="test icon"></img>
      <div>{node.subtitle}</div>
    </li>
  )
}

const PostPage = ({data}) => (
    <Layout>
    <SEO/>
    <div class="close"></div>
    <ul className="blog-post">
      {data.allContentfulBlog.edges.map((edge) => <BlogPost node={edge.node} />)}
    </ul>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    </div>

    </Layout>
)

export default PostPage

export const pageQuery = graphql`
  query pageQuery {
    allContentfulBlog (filter: {
      node_locale: {eq: "en-US"}
    },
    sort:{ fields: [date], order: ASC}
    ) {
      edges {
        node {
          title
          slug
          subtitle
          image {
            resize(width:300, height:300) {
              src
            }
          }
        }
      }
    }
  }  
`